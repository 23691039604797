import type { Role } from '../../interfaces/User/UserAuth0ApiType';

type EnvironmentConfig = {
  API_BASE_URL: string;
  AUTH0_BASE_URL: string;
  ROLES: {
    SUPER_ADMIN: string;
    ADMIN: string;
    USER: string;
  };
};

// Fetch environment or fallback to development
const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || 'local';

// Define configurations
const configs: Record<string, EnvironmentConfig> = {
  local: {
    API_BASE_URL: 'https://dev-api.cubeless.io',
    AUTH0_BASE_URL: 'http://localhost:3000',
    ROLES: {
      SUPER_ADMIN: 'rol_l1FuW3riBka6vQw6',
      ADMIN: 'rol_vhQjIHRDTAbcl6oq',
      USER: 'rol_ObxwJMksIsb8Td5W',
    },
  },
  development: {
    API_BASE_URL: 'https://dev-api.cubeless.io',
    AUTH0_BASE_URL: 'http://dev.cubeless.io',
    ROLES: {
      SUPER_ADMIN: 'rol_l1FuW3riBka6vQw6',
      ADMIN: 'rol_vhQjIHRDTAbcl6oq',
      USER: 'rol_ObxwJMksIsb8Td5W',
    },
  },
  production: {
    API_BASE_URL: 'https://api.cubeless.io',
    AUTH0_BASE_URL: 'https://app.cubeless.io',
    ROLES: {
      SUPER_ADMIN: 'rol_ANZM2PBT5dJyBHCo',
      ADMIN: 'rol_alhgCCwGqZ9d0peV',
      USER: 'rol_yQuD14MEkfxBi7Ii',
    },
  },
  staging: {
    API_BASE_URL: 'https://staging-api.cubeless.io',
    AUTH0_BASE_URL: 'https://demo.cubeless.io',
    ROLES: {
      SUPER_ADMIN: 'rol_jgbvviSmJEQmlboJ',
      ADMIN: 'rol_km3K6bshnZGHcFvG',
      USER: 'rol_gmVozWqA8DNZPGUg',
    },
  },
};

// Get the config based on the environment
const currentConfig = {
  ...configs[APP_ENV],
};

// Export individually
export const API_BASE_URL = currentConfig.API_BASE_URL;
export const AUTH0_BASE_URL = currentConfig.AUTH0_BASE_URL;
export const ROLES = currentConfig.ROLES;

// Utility function for class names
export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

// Add this to the bottom of the envConfig.ts file
export const ROLES_LIST: Role[] = [
  {
    id: ROLES.ADMIN,
    name: 'Administrator',
    description: 'Admin role',
  },
  {
    id: ROLES.USER,
    name: 'User',
    description: 'User role',
  },
  {
    id: ROLES.SUPER_ADMIN,
    name: 'Super Admin',
    description: 'Super Admin role',
  },
];

export const ROLES_MAP: Role[] = [
  {
    id: ROLES.ADMIN,
    name: 'Administrator',
    description: 'Admin role',
  },
  {
    id: ROLES.USER,
    name: 'User',
    description: 'User role',
  },
];
